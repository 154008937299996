import React, { useState } from 'react';
import { emphasize, makeStyles, withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import NoSsr from '@material-ui/core/NoSsr';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import MenuItem from '@material-ui/core/MenuItem';
import { getVariationTheme } from '../../../themes/theme';

const colorTheme = getVariationTheme();

/**
 * Style of Custom Text Field elements
 *
 */
const styles = () => (
  {
    textField: {
      width: '100%',
      backgroundColor: colorTheme.text_light,
      fontWeight: 'bold',
    },
    cssUnderline: {
      '&:after': {
        borderBottomColor: colorTheme.primary,
      },
    },
    cssLabel: {
      color: colorTheme.menu,
      fontWeight: 'bold',
      '&$cssFocused': {
        color: colorTheme.menu,
      },
    },
    cssLabelMandatory: {
      color: colorTheme.secondary,
      fontWeight: 'bold',
      '&$cssFocused': {
        color: colorTheme.secondary,
      },
    },
    cssFocused: {},
  }
);

const useStyles = makeStyles(theme => (
  {
    root: {
      flexGrow: 1,
      height: 250,
    },
    input: {
      display: 'flex',
      padding: 0,
      height: 'auto',
    },
    valueContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      flex: 1,
      alignItems: 'center',
      overflow: 'hidden',
    },
    chip: {
      margin: theme.spacing(0.5, 0.25),
    },
    chipFocused: {
      backgroundColor: emphasize(
        theme.palette.type === 'light'
          ? theme.palette.grey[300]
          : theme.palette.grey[700],
        0.08,
      ),
    },
    noOptionsMessage: {
      padding: theme.spacing(1, 2),
    },
    singleValue: {
      fontSize: 16,
    },
    placeholder: {
      position: 'absolute',
      left: 2,
      bottom: 6,
      fontSize: 16,
    },
    paper: {
      position: 'absolute',
      zIndex: 10,
      marginTop: theme.spacing(1),
      left: 0,
      right: 0,
      width: 400,
    },
    divider: {
      height: theme.spacing(2),
    },
  }
));

const selectStyles = {
  input: base => (
    {
      ...base,
      color: colorTheme.menu,
      '& input': {
        font: 'inherit',
      },
    }
  ),
};

function NoOptionsMessage(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.noOptionsMessage}
      {...props.innerProps}
    >
      {props.selectProps.noOptions}
    </Typography>
  );
}

NoOptionsMessage.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function inputComponent({ inputRef, ...props }) {
  return <div ref={inputRef} {...props} />;
}

inputComponent.propTypes = {
  inputRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
};

function Control(props) {
  const {
    children,
    innerProps,
    innerRef,
    selectProps: { classes, TextFieldProps },
  } = props;

  return (
    <TextField
      fullWidth
      InputProps={{
        inputComponent,
        inputProps: {
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
      }}
      {...TextFieldProps}
    />
  );
}

Control.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  selectProps: PropTypes.object.isRequired,
};

function Option(props) {
  return (
    <MenuItem
      ref={props.innerRef}
      selected={props.isFocused}
      component="div"
      style={{
        fontWeight: props.isSelected
          ? 500
          : 400,
      }}
      {...props.innerProps}
    >
      {props.children}
    </MenuItem>
  );
}

Option.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  innerRef: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  isFocused: PropTypes.bool,
  isSelected: PropTypes.bool,
};

function Placeholder(props) {
  return (
    <Typography
      color="textSecondary"
      className={props.selectProps.classes.placeholder}
      {...props.innerProps}
    >
      {props.children}
    </Typography>
  );
}

Placeholder.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function SingleValue(props) {
  return (
    <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
      {props.children}
    </Typography>
  );
}

SingleValue.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object.isRequired,
};

function ValueContainer(props) {
  return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

ValueContainer.propTypes = {
  children: PropTypes.node,
  selectProps: PropTypes.object.isRequired,
};

function Menu(props) {
  return (
    <Paper square className={props.selectProps.classes.paper} {...props.innerProps}>
      {props.children}
    </Paper>
  );
}

Menu.propTypes = {
  children: PropTypes.node,
  innerProps: PropTypes.object,
  selectProps: PropTypes.object,
};

const components = {
  Control,
  Menu,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
};

/**
 * Class CustomSelectField
 *
 * @Component : <CustomSelectField />
 */
const CustomSelectField = props => {
  const randomNumber = Math.floor(Math.random() * 101);
  const selectClasses = useStyles();
  // Constant
  const {
    id,
    label,
    onChange,
    value,
    isMandatory,
    classes,
    gridMD,
    noLabel,
    disabled,
    autocomplete,
    options,
    multiple,
    minLength = -1,
    noOptions = 'Aucune option',
    creatable = false,
  } = props;

  const [showOptions, setShowOptions] = useState(!props.creatable);

  // Element
  const textField = (
    <TextField
      className={classes.textField}
      select
      id={id}
      onChange={onChange}
      disabled={disabled}

      value={value
        ? value
        : ''}
      label={noLabel
        ? null
        : `${label}` + (
        isMandatory
          ? '*'
          : ''
      )}

      margin="normal"
      variant="outlined"
      InputLabelProps={{
        classes: {
          root: isMandatory
            ? classes.cssLabelMandatory
            : classes.cssLabel,
          focused: classes.cssFocused,
        },
      }}
    >
      {props.children}
    </TextField>
  );

  // AutoComplete

  const creatableAutoComplete = (
    <NoSsr>
      <CreatableSelect
        classes={selectClasses}
        styles={selectStyles}
        inputId={'react-select-single-' + randomNumber.toString()}
        TextFieldProps={{
          label: noLabel
            ? null
            : `${label}` + (
            isMandatory
              ? '*'
              : ''
          ),
          InputLabelProps: {
            htmlFor: 'react-select-single-' + randomNumber.toString(),
            shrink: true,
            classes: {
              root: isMandatory
                ? classes.cssLabelMandatory
                : classes.cssLabel,
              focused: classes.cssFocused,
            },
          },
          placeholder: noLabel
            ? null
            : `${label}` + (
            isMandatory
              ? '*'
              : ''
          ),
        }}
        options={showOptions
          ? options
          : []}
        components={components}
        value={value
          ? value
          : ''}
        onChange={onChange}
        onInputChange={input => setShowOptions(input.length > minLength)}
        isMulti={multiple}
        isClearable={true}
        placeholder={''}
        noOptions={noOptions}
        formatCreateLabel={inputValue => 'Créer l\'option: ' + inputValue}
      />
    </NoSsr>
  );

  const selectAutoComplete = (
    <NoSsr>
      <Select
        classes={selectClasses}
        styles={selectStyles}
        inputId={'react-select-single-' + randomNumber.toString()}
        TextFieldProps={{
          label: noLabel
            ? null
            : `${label}` + (
            isMandatory
              ? '*'
              : ''
          ),
          InputLabelProps: {
            htmlFor: 'react-select-single-' + randomNumber.toString(),
            shrink: true,
            classes: {
              root: isMandatory
                ? classes.cssLabelMandatory
                : classes.cssLabel,
              focused: classes.cssFocused,
            },
          },
          placeholder: noLabel
            ? null
            : `${label}` + (
            isMandatory
              ? '*'
              : ''
          ),
        }}
        options={showOptions
          ? options ?? []
          : []}
        components={components}
        value={value
          ? value
          : ''}
        onChange={onChange}
        onInputChange={input => setShowOptions(input.length > minLength)}
        isMulti={multiple}
        isClearable={true}
        placeholder={'Sélectionner...'}
        noOptions={noOptions}
      />
    </NoSsr>
  );

  return (
    <Grid item xs={12} sm={12} md={gridMD} onClick={props.onClick}>
      {autocomplete
        ? (
          creatable
            ? creatableAutoComplete
            : selectAutoComplete
        )
        : textField}
    </Grid>
  );
};

CustomSelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any,
  children: PropTypes.node,
  isMandatory: PropTypes.bool,
  classes: PropTypes.object.isRequired,
  gridMD: PropTypes.number,
  noLabel: PropTypes.bool,
  disabled: PropTypes.bool,
  autocomplete: PropTypes.bool,
};

CustomSelectField.defaultProps = {
  value: null,
  label: '',
  isMandatory: false,
  noLabel: false,
  disabled: false,
  gridMd: 4,
};

export default withStyles(styles)(CustomSelectField);
