import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useHistory, useLocation } from 'react-router';
import { useMediaQuery, useTheme } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText/ListItemText';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import CodeIcon from '@material-ui/icons/Code';
import Divider from '@material-ui/core/Divider/Divider';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer/Drawer';
import List from '@material-ui/core/List/List';
import AuthService from '../../services/AuthService';
import { ROLE_ADMIN } from '../../constants/roles';
import {
  CANDIDATES,
  CLIENTS,
  HOMEPAGE,
  OFFERS,
  SIGN_IN,
  TEMPLATES,
  USERS,
} from '../../constants/routes';
import HomeIcon from '../../components/icons/HomeIcon';
import ClientIcon from '../../components/icons/ClientIcon';
import SearchIcon from '../../components/icons/SearchIcon';
import OfferIcon from '../../components/icons/OfferIcon';
import { DEFAULT_UNSELECTED_MENU_ITEM } from '../../themes/defaultColors';
import LogOutIcon from '../../components/icons/LogOutIcon';
import { getVariationTheme } from '../../themes/theme';
import { auth } from '../../services/firebase';
import { unloadContacts } from '../../stores/contacts/actions';
import { unloadClients } from '../../stores/clients/actions';

const colorTheme = getVariationTheme();
export const drawerWidth = 240;
export const headerHeight = 100;

const useStyles = makeStyles(() => (
  {
    drawerPaper: {
      backgroundColor: colorTheme.menu,
      width: drawerWidth,
      margin: 'auto',
    },
    drawerHeader: {
      marginTop: headerHeight / 4,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: headerHeight,
    },
  }
));
const getIconColor = (selected) => {
  return selected
    ? colorTheme.primary
    : DEFAULT_UNSELECTED_MENU_ITEM;
};
const getTextColor = (selected) => {
  return selected
    ? colorTheme.text_light
    : DEFAULT_UNSELECTED_MENU_ITEM;
};
const Menu = (props) => {
  const {
    open,
    onClose,
  } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const classes = useStyles();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down('md'));
  const { authUser } = useSelector(state => state.users);
  const { content } = useSelector(state => state.roles);

  const handleLogout = () => {
    auth.doSignOut();
    dispatch(unloadContacts());
    dispatch(unloadClients());
    // TODO : handle logout if needed
    //this.props.logout();
    history.push(SIGN_IN);
  };

  const isAdmin = AuthService.isGranted(ROLE_ADMIN, authUser, content);

  return (
    <Drawer
      key="menu"
      variant={matches
        ? 'temporary'
        : 'permanent'}
      anchor="left"
      open={open}
      onClose={onClose}
      classes={{
        paper: classes.drawerPaper,
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <div>
        <div className={classes.drawerHeader}>
          <img alt="logo" src={colorTheme.logo_white} height={90}/>
        </div>

        <List>
          {isAdmin && (
            <Link to={HOMEPAGE} style={{ textDecoration: 'none' }}>
              <ListItem
                key="home"
                selected={location.pathname === HOMEPAGE}
              >
                <ListItemIcon>
                  <HomeIcon style={{ color: getIconColor(location.pathname === HOMEPAGE) }} />
                </ListItemIcon>
                <ListItemText
                  style={{ color: getTextColor(location.pathname === HOMEPAGE) }}
                  primary="Accueil"
                />
              </ListItem>
            </Link>
          )}
          <Link to={CLIENTS} style={{ textDecoration: 'none' }}>
            <ListItem
              key="clients"
              selected={location.pathname.includes(CLIENTS)}
              color="primary"
            >
              <ListItemIcon>
                <ClientIcon style={{ color: getIconColor(location.pathname.includes(CLIENTS)) }} />
              </ListItemIcon>
              <ListItemText
                style={{ color: getTextColor(location.pathname.includes(CLIENTS)) }}
                primary="Clients"
              />
            </ListItem>
          </Link>

          <Link to={CANDIDATES} style={{ textDecoration: 'none' }}>
            <ListItem
              key="candidates"
              selected={location.pathname.includes(CANDIDATES)}
            >
              <ListItemIcon>
                <SearchIcon
                  style={{ color: getIconColor(location.pathname.includes(CANDIDATES)) }} />
              </ListItemIcon>
              <ListItemText
                style={{ color: getTextColor(location.pathname.includes(CANDIDATES)) }}
                primary="CVthèque"
              />
            </ListItem>
          </Link>

          <Link to={OFFERS} style={{ textDecoration: 'none' }}>
            <ListItem
              key="offers"
              selected={location.pathname.includes(OFFERS)}
            >
              <ListItemIcon>
                <OfferIcon style={{ color: getIconColor(location.pathname.includes(OFFERS)) }} />
              </ListItemIcon>
              <ListItemText
                style={{ color: getTextColor(location.pathname.includes(OFFERS)) }}
                primary="Offres"
              />
            </ListItem>
          </Link>

          {isAdmin && (
            <>
              <Link to={USERS} style={{ textDecoration: 'none' }}>
                <ListItem
                  key="admins"
                  selected={location.pathname.startsWith(USERS)}
                >
                  <ListItemIcon>
                    <SupervisorAccountIcon
                      style={{ color: getIconColor(location.pathname.startsWith(USERS)) }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: getTextColor(location.pathname.startsWith(USERS)) }}
                    primary="Utilisateurs"
                  />
                </ListItem>
              </Link>
              <Link to={TEMPLATES} style={{ textDecoration: 'none' }}>
                <ListItem
                  key="admins"
                  selected={location.pathname.startsWith(TEMPLATES)}
                >
                  <ListItemIcon>
                    <CodeIcon
                      style={{ color: getIconColor(location.pathname.startsWith(TEMPLATES)) }} />
                  </ListItemIcon>
                  <ListItemText
                    style={{ color: getTextColor(location.pathname.startsWith(TEMPLATES)) }}
                    primary="Modèles Excel"
                  />
                </ListItem>
              </Link>
            </>
          )}
        </List>

        <Divider style={{ backgroundColor: DEFAULT_UNSELECTED_MENU_ITEM }} />

        <List>
          <ListItem key="logout" onClick={handleLogout} style={{ cursor : 'pointer'}}>
            <ListItemIcon>
              <LogOutIcon style={{ color: getIconColor(location.pathname === SIGN_IN) }} />
            </ListItemIcon>
            <ListItemText
              style={{ color: getTextColor(location.pathname === SIGN_IN) }}
              primary="Se déconnecter"
            />
          </ListItem>
        </List>
      </div>
    </Drawer>
  );
};

export default Menu;
