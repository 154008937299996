import { DEFAULT_WHITE } from '../defaultColors';
import logo from '../../resources/images/logo_adapei.svg';
import logoWhite from '../../resources/images/logo_adapei_white.svg';
import header from '../../resources/images/header_adapei.png';

export const ADAPEI_PRIMARY = '#99C01C';
export const ADAPEI_SECONDARY = '#116A8F';


export const ADAPEI_LABEL = '#213A66';

export const ADAPEI_SUCCESS = '#99C01C';
export const ADAPEI_ERROR = '#99000E';
export const ADAPEI_WARNING = '#D06519';


export const ADAPEI_MENU = '#116A8F';
export const ADAPEI_BACKGROUND = '#213A66';
export const ADAPEI_TEXT_DARK = '#0A172C';
export const ADAPEI_TEXT_PLACEHOLDER = '#B0B4B8';
export const ADAPEI_TABLE_HEAD = '#0A172C';

const adapei_variation = {
  font : "Verdana",
  primary: ADAPEI_PRIMARY,
  secondary: ADAPEI_SECONDARY,
  label: ADAPEI_LABEL,
  info: ADAPEI_PRIMARY,
  success: ADAPEI_SUCCESS,
  warning: ADAPEI_WARNING,
  error: ADAPEI_ERROR,
  menu: ADAPEI_MENU,
  background: ADAPEI_BACKGROUND,
  text_light: DEFAULT_WHITE,
  text_dark: ADAPEI_TEXT_DARK,
  text_placeholder: ADAPEI_TEXT_PLACEHOLDER,
  table_head: ADAPEI_TABLE_HEAD,
  logo: logo,
  logo_white: logoWhite,
  app_bar_background: `url(${header})`
}

export default adapei_variation;
