import React from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import background from '../../resources/images/mire_ava_talent.png';
import { defaultStyles, getVariationTheme } from '../../themes/theme';

const colorTheme = getVariationTheme();
const useStyles = makeStyles(theme => (
  {
    backgroundImage: {
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    loginFormContainer: {
      backgroundColor: '#fafafa',
    },
  }
));
const LoginLayout = (props) => {
  const { children } = props;
  const classes = useStyles();
  return (
    <Grid container wrap='nowrap' justifyContent='space-between'>
      <Grid item md={6} lg={6} xl={6}>
        <img
          alt="background"
          src={background}
          className={classes.backgroundImage}
          style={defaultStyles.backgroundImage}
        />
      </Grid>
      <Grid item xs={12} md={6} lg={6} xl={6} className={classes.loginFormContainer}>
        <div style={defaultStyles.center}>
          <img alt="logo" src={colorTheme.logo} style={defaultStyles.backgroundLogo} height='200px'/>
          {children}
        </div>
      </Grid>
    </Grid>
  );
};

export default LoginLayout;
